import { BASE_URLS } from '../common/constants';
import commonAxios from './commonAxios';

const getCmsBaseUrl = () => {
  let baseUrl = BASE_URLS.CMS_PROD;
  console.log('GATSBY_STRAPI_URL passed in: ' + process.env.GATSBY_STRAPI_URL);
  console.log('(GATSBY_)STRAPI_URL passed in: ' + process.env.STRAPI_URL);

  if (process.env.GATSBY_STRAPI_URL) {
    let envBaseUrl = process.env.GATSBY_STRAPI_URL;
    if (!envBaseUrl.endsWith('/')) {
      baseUrl = envBaseUrl + '/';
    } else {
      baseUrl = envBaseUrl;
    }
  }

  console.log('-> Computed API base URL: ' + baseUrl);
  return baseUrl;
};

const token = process.env.GATSBY_PUBLIC_STRAPI_TOKEN || '';

const cmsApiSerivce = () => commonAxios(getCmsBaseUrl(), token);

export default cmsApiSerivce;
