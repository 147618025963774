import axios from 'axios';
import { EMPTY_TOKEN } from '../common/constants';

export default function commonAxios(
  baseUrl,
  token, // optional
  useDefaultHeader = true
) {
  const instance = axios.create(
    useDefaultHeader
      ? {
          baseURL: baseUrl,
          timeout: 30000,
          headers: {
            accept: 'application/json',
            'Content-Type': 'application/json',
          },
        }
      : {
          baseURL: baseUrl,
          timeout: 30000,
        }
  );

  if (token && token !== EMPTY_TOKEN) {
    instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }

  instance.interceptors.response.use(
    (response) => response.data,
    (error) => Promise.reject(error)
  );

  return instance;
}
