import React from 'react';

import './Footer.scss';

const index = () => {
  return (
    <section className='section bottom-section'>
      <div className='container'>
        <div className='columns'>
          <div className='column pt-0 pb-1'>
            <div className='small-uppercase'>CONTACT</div>
          </div>
        </div>
        <div className='columns'>
          <div className='column'>
            <p>General enquiries</p>
            <a
              href='mailto:info@wigramcap.com'
              // className='is-hidden-tablet is-hidden-desktop'
            >
              info@wigramcap.com
            </a>
          </div>
          <div className='column'>
            <p>
              To register your interest for upcoming positions send a cover
              letter and cv to{' '}
              <a href='mailto:careers@wigramcap.com'>careers@wigramcap.com</a>
            </p>
            {/* <Link to='/careers' className='is-hidden-tablet is-hidden-desktop'>
              Careers
            </Link> */}
            <div className='copyright-text is-hidden-desktop is-hidden-tablet'>
              © Wigram Capital Advisors Ltd
            </div>
          </div>
        </div>

        <div className='columns'>
          <div className='column'>
            <div className='copyright-text is-hidden-mobile'>
              © Wigram Capital Advisors Ltd
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default index;
