import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import { Link, navigate } from 'gatsby';

import './Navbar.scss';
import Logo from '../../images/logo.svg';
import LogoBlack from '../../images/logo-black.svg';
import Overlay from '../Overlay';
import CloseIconWhite from '../../images/close-icon-white.svg';

function Navbar({ showLogin, isDarkLogo }) {
  return (
    <div className='top-nav is-hidden-mobile'>
      <div className='container'>
        <div className='columns is-full nav-desktop'>
          <div className='column'>
            <div className='logo-wrapper'>
              <Link to='/'>
                {!isDarkLogo ? <Logo /> : <LogoBlack height={43} width={148} />}
              </Link>
            </div>
          </div>

          {showLogin && (
            <button
              className='button is-primary'
              id='btn-login'
              onClick={() => navigate('subscribe')}
            >
              <span>Login</span>
            </button>
          )}

          <div className='column nav-menu'>
            <nav className={`nav-list ${isDarkLogo ? 'nav-dark' : ''}`}>
              <Link className='nav-link' to='/'>
                Home
              </Link>
              <Link className='nav-link' to='/careers'>
                Careers
              </Link>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}

export function NavbarMobile({ showLogin, className }) {
  const [isOpenNav, setIsOpenNav] = useState(false);
  const mobileMenuNav = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        isOpenNav &&
        mobileMenuNav.current &&
        !mobileMenuNav.current.contains(event.target)
      ) {
        setIsOpenNav(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [mobileMenuNav, isOpenNav]);

  return (
    <div
      className={`top-nav mobile is-hidden-tablet is-hidden-desktop ${className}`}
    >
      <div className='columns pl-5 pr-5 pt-5'>
        <div className='columns'>
          <div className='column nav-mobile'>
            <div className='logo-wrapper'>
              <Link to='/'>
                <LogoBlack />
              </Link>
            </div>

            <div className='nav-mobile'>
              <div className='hamburger-btn' onClick={() => setIsOpenNav(true)}>
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          </div>

          {showLogin && (
            <button
              className='button is-primary'
              id='btn-login'
              onClick={() => navigate('subscribe')}
            >
              <span>Login</span>
            </button>
          )}
        </div>
      </div>
      <div
        ref={mobileMenuNav}
        className={`mobile-nav-menu ${isOpenNav ? 'show' : ''}`}
      >
        <div className='close-btn' onClick={() => setIsOpenNav(false)}>
          <CloseIconWhite width={14} height={14} />
        </div>
        <nav className={`nav-list`}>
          <Link className='nav-link' to='/'>
            Home
          </Link>
          <Link className='nav-link' to='/careers'>
            Careers
          </Link>
        </nav>
      </div>
      <Overlay isShow={isOpenNav} />
    </div>
  );
}

export default Navbar;
