// default base urls
export const BASE_URLS = {
  CMS_PROD: 'https://cms.wigramcap.com/api',
  API_PROD: 'https://api.wigramcap.com',
  CMS: 'https://cms-dev.wigramcap.com/api',
  API: 'https://api-dev.wigramcap.com',
};

export const EMPTY_TOKEN = '-';

export const TOKEN = 'token';
