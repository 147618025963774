import React from 'react';

import './Overlay.scss';

const Overlay = ({ isShow }) => {
  if (!isShow) return null;

  return <div className='overlay'></div>;
};

export default Overlay;
